import React, { Component } from "react";
import {
  Row,
  Panel,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Button
} from "react-bootstrap";
import { withRouter } from 'react-router'
import Axios from "axios";

class CategoryForm extends Component {

  state = {
    id: null,
    name: null,
    weight: null,
  }

  componentDidMount() {
    if (!this.props.match.params.id || isNaN(this.props.match.params.id)) {
      return
    }
    const id = this.props.match.params.id
    Axios.get('https://api.hoevehoogland.nl/categories/' + id)
    .then(res => {
      this.setState(res.data)
    })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  submitForm = (event) => {
    event.preventDefault()

    if (this.state.id) {
      Axios.put('https://api.hoevehoogland.nl/categories/' + this.state.id, this.state)
      .then(res => {
        this.props.history.push("/admin/categories")
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Axios.post('https://api.hoevehoogland.nl/categories', this.state)
      .then(res => {
        this.props.history.push("/admin/categories")
      })
      .catch(error => {
        console.log(error)
      })      
    }
  }

  render() {
    return (
      <Panel bsStyle="success">
        <Panel.Heading>
          <h2>Categorie</h2>
        </Panel.Heading>
        <Panel.Body>
          <form onSubmit={this.submitForm}>
            <Row>
              <Col md={6}>
                <FormGroup controlId="CategoryFormName">
                  <ControlLabel>Naam</ControlLabel>
                  <FormControl
                    required  
                    name="name"
                    type="text"
                    defaultValue={this.state.name}
                    placeholder="Naam van de categorie" 
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup controlId="CategoryFormSubmit">
              <Button bsStyle="primary" type="submit">
                Opslaan
              </Button>
            </FormGroup>
          </form>
        </Panel.Body>
      </Panel>
    );
  }
}
export default withRouter(CategoryForm)