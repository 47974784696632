import React,{Component} from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Glyphicon } from 'react-bootstrap'
import Axios from 'axios';

class DeleteButton extends Component {

    handle = (e) => {
        e.preventDefault()
        if (window.confirm("Weet je zeker dat je dit wilt verwijderen?")) {
            Axios.delete("https://api.hoevehoogland.nl/" + this.props.entity + "/" + this.props.id)
            .then(res => {
                window.location.reload()
            })
        }
    }

    render() {
        return(
            <Button bsSize="xs" title="Verwijder" onClick={this.handle}>
                <Glyphicon glyph="trash"></Glyphicon>
            </Button>
        )
    }
}

export default withRouter(DeleteButton)