import { observable, action } from 'mobx'
import Axios from 'axios'

class OrdersStore {
    @observable orders = []

    @action
    getOrders() {
      Axios.get('https://api.hoevehoogland.nl/orders')
      .then(res => {
        this.orders = res.data
      })
      .catch(error => {
        
      })
    }
}

export default OrdersStore