import React,{Component} from 'react'
import { inject, observer } from 'mobx-react'
import axios from 'axios'
import { withRouter } from 'react-router'

@inject('user', 'session')
@observer
class AppContainer extends Component {

    user = null

    constructor(props) {
        super(props)
    
        const user = sessionStorage.getItem('user')
        if (user) {
            this.props.user.setUser(JSON.parse(user))
        }

        axios.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
          }, function (error) {
            if (error.repsonse) {
                props.session.setMessage(error.response.data.message, "danger")
            } 

            if (error.response && error.response.status === 401) {
                props.history.push("/login")
                props.user.logout();
            }
            return Promise.reject(error);
          });
    }

    componentDidMount() {
        if (this.props.user.token === null) {
            this.props.history.push("/login")
        }
    }

    render() {
        if (this.props.user.token === null) {
            return null
        }

        return (
            <div>
                {this.props.children}
            </div>
        )
    }

}

export default withRouter(AppContainer)