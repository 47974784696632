import React, {Component} from 'react'
import List from './List'
import { observer, inject } from 'mobx-react';

@inject('products')
@observer
class ProductsList extends Component {

    componentDidMount() {
        this.props.products.getProducts()
    }

    render() {
        const fields = {
            'id': 'ID', 
            'name': 'Titel',
            'category': 'Categorie',
            'unit_value': 'Hoeveelheid',
            'price': 'Prijs'
        }

        const hidden = ['enabled', 'highlight', 'category_id', 'image', 'unit_id', 'category_weight']
        
        return (
            <List title="Producten" showToggle={true} hidden={hidden} enableDelete={true} showHighlight={true} plural_entity="products" entries={this.props.products.normalized} fields={fields} />
        )
    }
}

export default ProductsList