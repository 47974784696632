import React, {Component} from 'react'
import {Button} from 'react-bootstrap'
import { inject, observer } from 'mobx-react';
import accounting from 'accounting'
import './style.css'

@inject('cart')
@observer
class ProductTile extends Component {

    addItem = () => {
        this.props.cart.addItem(this.props.product)
    }

    removeItem = () => {
        this.props.cart.removeItem(this.props.product)
    }

    render() {
        const id = this.props.product.id
        const hideAmount = {
            display: (this.props.cart.items[id]) ? 'inline-block' : 'none'
        } 

        const productClass = this.props.highlight ? 'product highlight' : 'product'

        let imageSrc = "https://via.placeholder.com/400x300"
        if (this.props.product.image) {
            imageSrc = "https://api.hoevehoogland.nl/" + this.props.product.image
        }

        let productImage = {
                content: "",
                background: "url(" + encodeURI(imageSrc) + ")",
                backgroundSize: "cover",
                backgroundPosition: 'center center',
                opacity: 0.3,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                zIndex: -1
        }
        
        return(
            <div className={productClass} >
                    <div className="product-title">{ this.props.product.name }</div>
                    <div className="product-unit">{ this.props.product.unit_value }</div>
                    <div className="product-price pull-right">&euro; { accounting.formatNumber(this.props.product.price / 100,2,".",",") }</div>
                    <div className="product-buttons">
                    <Button onClick={this.removeItem} style={ hideAmount}>-</Button>
                    <span className="product-amount" style={ hideAmount }>{ this.props.cart.items[id] ? this.props.cart.items[this.props.product.id] : null }</span>
                    <Button  bsStyle="success" onClick={this.addItem}>+</Button>
                    </div>

                <div style={productImage}>
                </div>
            </div>   
        )
    }
}

export default ProductTile