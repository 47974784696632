import React,{Component} from 'react'
import { inject } from 'mobx-react';
import accounting from 'accounting'
import OrderDetails from './OrderDetails'
import { Row, Col } from 'react-bootstrap'
import './order.css'
import OrderPicked from './OrderPicked';

@inject('userList')
class Order extends Component {

    state = {
        showDetails: false
    }

    username(id) {
        const user = this.props.userList.getUser(id)
        return user ? user.name : 'Onbekend'
    }

    totalInEurors(total) {
        return accounting.formatNumber(total/100, 2, ".", ",")
    }

    nlDate(date) {
        return date
    }

    showDetails = () => {
        this.setState({'showDetails': !this.state.showDetails})
    }

    render() {
        const order = this.props.order
        const clickStyle = { cursor: "pointer"}
        return(
            <div className={this.state.showDetails ? "order order-expanded" : "order"} >
                <Row className={order.finished ? "finished" : null}>
                    <Col md={2} style={clickStyle} onClick={this.showDetails}>#{ order.id }</Col>
                    <Col md={2} style={clickStyle} onClick={this.showDetails}>{ this.username(order.user_id) }</Col>
                    <Col md={2} style={clickStyle} onClick={this.showDetails}>&euro; { this.totalInEurors(order.total) }</Col>
                    <Col md={3} mdOffset={3}>{ this.nlDate(order.created_at) } <OrderPicked checked={order.finished ? true : false} orderId={order.id} /></Col>
                </Row>
                {this.state.showDetails && <OrderDetails id={order.id} /> }
            </div>
        )        
    }

}

export default Order