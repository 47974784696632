import React, {Component} from 'react'
import {Badge, Glyphicon} from 'react-bootstrap'
import { inject, observer } from 'mobx-react'
import './style.css'

@inject('cart')
@observer
class ShopBasket extends Component {
    render() {

        const cartStyle = {
            fontSize: 18,
            zIndex: 5,
            color: "#ffffff"
        }

        const badgeStyle = {
            backgroundColor: "#5cb85c"
        }

        return (
                <div style={{position: "relative", marginRight: 5}}>
                    <Glyphicon bsSize="large" glyph="shopping-cart" style={cartStyle} />
                    <Badge className="cart-badge" style={this.props.cart.total ?  badgeStyle : null}>{ this.props.cart.total }</Badge>
                </div>
        )
    }    
}

export default ShopBasket