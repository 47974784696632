import { observable, action, computed } from 'mobx'
import accounting from 'accounting'
import Axios from 'axios'   

class UsersStore {
    @observable users = []

    @action
    getUsers() {
        Axios.get('https://api.hoevehoogland.nl/users')
        .then(res => {
          this.users = res.data
        })
    }

    @computed
    get getUsersWithNormalizedCredits() {
        let users = this.users
        for (let i in users) {
            users[i].credits = accounting.formatMoney(this.users[i].credits /100, "€", 2, ".", ",")
        }
        return users
    }

    @action
    getUser(id) {
        return this.users.find((user) => user.id === parseInt(id))
    }
}

export default UsersStore