import './App.css'
import React,{Component} from 'react';
import Main from './components/Main'
import Header from './components/Header'
import {Provider} from 'mobx-react'
import {Grid} from 'react-bootstrap'
import stores from './stores'

class App extends Component {
  render() { 
    return(
        <Provider {...stores}>
          <div className="App">
            <Header />
            <Grid>
              <Main />
            </Grid>
          </div>
        </Provider>
    )
  }
}


export default App;
