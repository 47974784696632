import React,{Component } from 'react'
import {FormControl} from 'react-bootstrap'
import { inject, observer } from 'mobx-react'

@inject('unitsList')
@observer
class UnitsSelect extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.onChangedUnit(e.target.value)
    }

    render() {
        const value = this.props.value ? this.props.value : ""
        const list = this.props.unitsList.units.map(val => {
            return(<option key={val.id} value={val.id}>{val.name}</option>)
        })
        list.unshift(<option key={-1}>- selecteer eenheid -</option>)
        return(<FormControl value={value} onChange={this.handleChange} componentClass="select" name="unit_id">{list}</FormControl>)
    }
}

export default UnitsSelect