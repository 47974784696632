import CategoryStore from './CategoryStore'
import ProductStore from './ProductStore'
import CartStore from './CartStore'
import UsersStore from './UsersStore'
import OrdersStore from './OrdersStore'
import UnitsStore from './UnitsStore'
import AuthUserStore from './AuthUserStore'
import SessionFlashBagStore from './SessionFlashBagStore'

const categories = new CategoryStore()
const products = new ProductStore()
const cart = new CartStore()
const userList = new UsersStore()
const orderList = new OrdersStore()
const unitsList = new UnitsStore()
const user = new AuthUserStore()
const session = new SessionFlashBagStore()

export default { cart, categories, products, userList, orderList, unitsList, user, session }