import { observable, action } from 'mobx'
import Axios from 'axios'   

class UnitsStore {
    @observable units = []

    @action
    getUnits() {
        Axios.get('https://api.hoevehoogland.nl/units')
        .then(res => {
          this.units = res.data
        })
    }

    @action
    getUnit(id) {
        console.log(id, this.units)
        return this.units.find(function(unit) {
            const result = parseInt(unit.id) === parseInt(id)
            return result
        })
    }
}

export default UnitsStore