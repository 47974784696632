import React, {Component} from 'react'
import {Col, Row, Button} from 'react-bootstrap'
import accounting from 'accounting'
import { inject, observer } from 'mobx-react';
import './style.css'

@inject('cart')
@observer
class ProductLine extends Component {
    addItem = () => {
        this.props.cart.addItem(this.props.product)
    }

    removeItem = () => {
        this.props.cart.removeItem(this.props.product)
    }

    render() {
        const id = this.props.product.id
        const hideAmount = {
            display: (this.props.cart.items[id]) ? 'inline-block' : 'none'
        } 

        const quantity = this.props.cart.items[this.props.product.id]
        const lineTotal = quantity ? accounting.formatNumber(quantity * this.props.product.price/100, 2, ".", ",") : null

        return(
            <Row className="product-line">
                <Col md={3}>{this.props.product.name}</Col>
                <Col md={3}>{this.props.product.unit_value} {this.props.product.unit}</Col>
                <Col md={1}>&euro;{ accounting.formatNumber(this.props.product.price / 100, 2, ".", ",")}</Col>
                <Col md={2} className="product-actions-container">
                <div className="product-actions">
                    <Button bsStyle="success"  onClick={this.addItem}>+</Button>
                    <span className="product-amount" style={ hideAmount }>{ this.props.cart.items[id] ? this.props.cart.items[this.props.product.id] : null }</span>
                    <Button onClick={this.removeItem} style={ hideAmount}>-</Button>
                </div>
                </Col>
                <Col md={3} className="product-line-total">
                    { lineTotal && <div>&euro; {lineTotal}</div> }
                </Col>
            </Row>
        )
    }
}

export default ProductLine