import React, {Component} from 'react'
import Axios from 'axios';
import {Row,Col} from 'react-bootstrap'
import { inject } from 'mobx-react';
import accounting from 'accounting'

@inject('products')
class OrderDetails extends Component {

    state = {
        details: []
    }

    productName = (id) => {
        const product = this.props.products.getProduct(id)
        return product.name
    }

    componentDidMount() {
        Axios.get('https://api.hoevehoogland.nl/orders/' + this.props.id)
        .then(res => {
            this.setState({'details': res.data})
        })
    }

    render() {
        const lines = this.state.details.map(line => {
            return (
                <Row key={line.id}>
                    <Col md={2}>{line.product_name}</Col>
                    <Col md={2}>{line.unit_value} {line.unit_name }</Col>
                    <Col md={2}>{line.quantity}</Col>
                    <Col md={2}>{ accounting.formatNumber(line.price_each/100, 2, ".", ",")}</Col>
                    <Col md={2}>{ accounting.formatNumber(line.price_each * line.quantity/100, 2, ".", ",")}</Col>
                </Row>
            )
        })

        return(
            <div className="orderDetails">
                <Row>
                    <Col md={2}>Product</Col>
                    <Col md={2}>Hoeveelheid</Col>
                    <Col md={2}>Aantal</Col>
                    <Col md={2}>Prijs per stuk</Col>
                    <Col md={2}>Subtotaal</Col>
                </Row>
            {lines}
            </div>
        )
    }

}

export default OrderDetails