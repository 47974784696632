import React, {Component} from 'react'
import { Grid, Button, Col, FormGroup, FormControl,ControlLabel } from 'react-bootstrap'

import Axios from 'axios';
import {Link, withRouter} from 'react-router-dom'
import { inject, observer } from 'mobx-react';

@inject('user')
@observer
class LostPassword extends Component {
    state = {
        email: null
    }

    handleSubmit = (event) => {
        event.preventDefault()
        Axios.post('https://api.hoevehoogland.nl/auth/change-password', {
            'email': this.state.email,
        })
        .then(res => {
            this.props.history.push("/password-sent")
        })
    }

    handleChange = (event) => {
        this.setState({email: event.target.value})
    }

    render() {
        return(
          <Grid className="login">
            <h2>Vergeten?</h2>
                <p>Ach, dat gebeurt ons allemaal wel eens. </p>
                <p>Vul uw <b>e-mailadres</b> in en klik op <b>versturen</b> en wij sturen u een mail met instructies</p>
              <form onSubmit={this.handleSubmit}>
              <Col>
                <FormGroup>
                  <ControlLabel>E-mail</ControlLabel>
                  <FormControl
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="myemail@email.com"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Button type="submit" bsStyle="primary">Versturen</Button>
              <Link to="/login" className="lost-password">Terug naar inlogscherm</Link>
            </form>
          </Grid>
        )
    }   
}

export default withRouter(LostPassword)