import { observable, action } from 'mobx'
import Axios from 'axios'

class CategoryStore {
    @observable categories = []

    @action
    getCategories() {
        Axios.get('https://api.hoevehoogland.nl/categories')
        .then(res => {
          this.categories = res.data
        })
    }

    @action
    getList() {
        return this.categories
    }
}

export default CategoryStore