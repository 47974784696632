import React, {Component} from 'react'
import axios from 'axios';

class OrderPicked extends Component {

    state = {
        checked: false
    }

    componentDidMount() {
        if (this.props.checked === true) {
            this.setState({checked: true})
        }
    }

    style = {
        marginLeft: 15
    }

    handleClick = (e) => {
        this.setState({checked: !this.state.checked})
        axios.post("/orders/" + parseInt(this.props.orderId) + "/picked", { picked: !this.state.checked})
        .then(res => {
            console.log('foo')
        })
        .catch(error => {
            console.log('whoops')
        })
    }

    render() {
        return(
            <div style={this.style} className={"pull-right"}><input type="checkbox" onChange={this.handleClick} checked={this.state.checked} /></div>
        )
    }

}

export default OrderPicked