import React, {Component} from 'react'
import { Row,Col } from 'react-bootstrap'
import ProductTile from './ProductTile'
import { inject, observer } from 'mobx-react';

@inject('products')
@observer
class ProductHighlights extends Component {

    highlightsStyle = {
        'marginTop': '35px',
        'marginBottom': '25px',
    }

    render() {  
        const ProductList = this.props.products.highlights.map(function(product) {
            return (
                <Col md={3} key={product.id}>
                    <ProductTile highlight={true} showImage={true} product={product} />
                </Col>
            )
        })
        return (
            <Row style={this.highlightsStyle}>{ ProductList }</Row>
        )
    }
}

export default ProductHighlights