import React, {Component} from 'react'
import {Glyphicon,Breadcrumb,ButtonToolbar,Table, Panel, Button, Clearfix} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import EditButton from '../EditButton'
import DeleteButton from '../DeleteButton'
import ToggleButton from '../ToggleButton'
import HighlightButton from '../HighlightButton'

class List extends Component {

    render = () => {
        const heading = Object.values(this.props.fields).map(function(field) {
            return <th key={field}>{ field }</th>
        })
        const pluralEntity = this.props.plural_entity;
        const tableAlignment = { textAlign: 'left'}
        const entries = this.props.entries.map(entry => {
            const cellEntry = Object.keys(this.props.fields).map((field) => {
                if (this.props.hidden && this.props.hidden.indexOf(field) !== -1) {
                    return null
                }
                return <td key={"field-" + field + "-" + entry['id']} style={ tableAlignment}>{ entry[field] }</td>
            })
            
            return ( 
            <tr key={"item-"  + entry['id']}>
                { cellEntry }
                <td>
                    <ButtonToolbar>
                        <EditButton entity={pluralEntity} id={entry['id']} />
                        { this.props.enableDelete && <DeleteButton entity={pluralEntity} id={entry['id']} /> }
                        { this.props.showToggle && <ToggleButton entity={pluralEntity} id={entry['id']} enabled={entry['enabled']} /> }
                        { this.props.showHighlight && <HighlightButton entity={pluralEntity} id={entry['id']} highlight={entry['highlight']} /> }
                    </ButtonToolbar>
                </td>
            </tr>
            )
        })

        const createLink = "/admin/" + pluralEntity + "/create"

        return(
            <div>
            <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/admin">Beheer</Breadcrumb.Item>
            <Breadcrumb.Item active>{this.props.title}</Breadcrumb.Item>
            </Breadcrumb>
            <Panel bsStyle="success">
                <Panel.Heading>
                    <Clearfix>
                    <h2 className="pull-left">{ this.props.title }</h2>
                    { this.props.disableCreate ? 
                    null :
                    <Link to={createLink}>
                        <Button bsSize="small" className="pull-right"><Glyphicon glyph="plus"></Glyphicon> Toevoegen</Button>
                    </Link>
                    }
                    </Clearfix>
                </Panel.Heading>
            
                { this.props.entries.length !== 0 ?     
                <Table striped>
                    <thead>
                        <tr>
                            { heading }
                            <th>Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        { entries }
                    </tbody>
                </Table>
                : 
                <Panel.Body>Er zijn (nog) geen items aanwezig</Panel.Body> }
            </Panel>
            </div>
        )
    }

}

export default List