import React, {Component} from 'react'
import {Glyphicon, Panel} from 'react-bootstrap'
import {Link} from 'react-router-dom'

class AdminTile extends Component {

    tileStyle = {
        textAlign: 'center'
    }

    panelStyle = {
        display: 'flex',
        height: 175,
        justifyContent: 'center',
        alignItems: 'center'
    }

    render() {
        return(
            <Link to={this.props.to}>
                <Panel style={this.panelStyle}>
                    <Panel.Body style={this.tileStyle}>
                        <h2><Glyphicon glyph={this.props.icon} /></h2>
                        <p>{this.props.label}</p>
                    </Panel.Body>
                </Panel>
            </Link>
        )
    }
}

export default AdminTile