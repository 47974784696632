import React, {Component} from 'react'
import {LinkContainer} from 'react-router-bootstrap'
import {Button} from 'react-bootstrap'

class CheckoutComplete extends Component {
    render = () => {
        return(
            <div>
                <h3>Bedankt!</h3>
                <p>Wij danken u voor uw bestelling. Binnen enkele minuten ontvangt u een kopie van uw bestelling in uw mailbox.</p>
                <LinkContainer to="/">
                    <Button href="/">Terug naar productenoverzicht</Button>
                </LinkContainer>
            </div>
        )
    }   
}

export default CheckoutComplete