import React, {Component} from 'react'
import { Alert, Grid, Button, Col, HelpBlock, FormGroup, FormControl,ControlLabel } from 'react-bootstrap'
import Axios from 'axios';
import {Link, withRouter} from 'react-router-dom'
import { inject, observer } from 'mobx-react';

@inject('user')
@observer
class ChangePassword extends Component {
    state = {
        tokenValid: false,
        password: null,
        strongPassword: false,
        helpText: null,
        passwordChanged: false
    }

    verifyToken = () => {
        Axios.post('https://api.hoevehoogland.nl/auth/validateToken', {
            type: 'password',
            token: atob(this.props.match.params.token)
        })
        .then(res => {
            this.setState({tokenValid: true})
        })
        .catch(error => {
            this.setState({tokenValid: false})
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        Axios.post('https://api.hoevehoogland.nl/auth/reset-password', {
            'token': atob(this.props.match.params.token),
            'new_password': this.state.password
        })
        .then(res => {
            this.props.history.push("/login?password-changed")
        })
    }

    validatePassword = (password) => {
        if (!password) {
            return
        } 

        let score = 0
        const alphabet = 'abcdefghijklmnopqrstuvwxyz'
        const symbols = "%$#@!^&*() |.,\""
        for (let i = 0; i < password.length; i++) {
            const char = password[i]
            let inAlphabet = alphabet.indexOf(password[i])
            if (!isNaN(char) || (inAlphabet !== -1 && char === char.toLowerCase())) {
                score += 1
            }
            inAlphabet = alphabet.indexOf(password[i].toLowerCase())
            if (inAlphabet !== -1 && char === char.toUpperCase()) {
                score += 2
            }
            if (symbols.indexOf(char) !== -1) {
                score += 4
            }
        }

        if (password.length < 8) {
            this.setState({strongPassword: false, helpText: 'Wachtwoord moet minimaal 8 karakters lang zijn'})
            return
        }
        if (score < password.length + 4 || score > password.length * 4 - 5) {
            this.setState({strongPassword: false, helpText: 'Wachtwoord moet minimaal 1 hoofdletter, 1 kleine letter en een leesteken bevatten'})
            return
        }
        this.setState({strongPassword: true, helpText: null})
    }

    handleChange = (event) => {
        const name = event.target.name
        this.setState({[name]: event.target.value})
        this.validatePassword(event.target.value)
    }

    componentDidMount() {
        this.verifyToken()
    }

    render() {
        if (!this.state.tokenValid) {
            return(
                <Grid className="login">
                    <h2>Link verlopen</h2>
                    <p>Helaas is uw link al verlopen. Klik op onderstaande link om een nieuwe reset-link aan te vragen.</p>
                    <Link to="/lost-password" className="lost-password">Reset-link aanvragen</Link>
                </Grid>
            )
        }
        
        if (this.state.passwordChanged) {
            return(
                <Grid className="login">
                    <Alert bsStyle="info"></Alert>
                </Grid>
            )
        }

        return(
          <Grid className="login">
            <h2>Nieuw wachtwoord</h2>
              <p>Vul in onderstaand vak uw nieuwe wachtwoord in en klik op de <b>Wijzigen</b> knop uw wachtwoord te wijzigen.</p>
              <form onSubmit={this.handleSubmit}>
              <Col>
                <FormGroup validationState={this.state.strongPassword ? "success" : "error"}>
                  <ControlLabel>Wachtwoord</ControlLabel>
                  <FormControl
                    type="password"
                    name="password"
                    onChange={this.handleChange}
                   />
                    { this.state.helpText && <HelpBlock>{this.state.helpText}</HelpBlock> }
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
              <Button type="submit" disabled={!this.state.strongPassword} bsStyle="primary">Wijzigen</Button>
              <Link to="/login" className="lost-password">Annuleren </Link>
            </form>
          </Grid>
        )
    }   
}

export default withRouter(ChangePassword)