import React, {Component} from 'react'
import List from './List'
import { inject, observer } from 'mobx-react';

@inject('unitsList')
@observer
class UnitsList extends Component {
    componentDidMount() {
        this.props.unitsList.getUnits()
    }

    render() {
        const fields = {
            'id': 'ID',
            'name': 'Titel'
        }
        
        return (
            <List title="Eenheden" plural_entity={"units"} entries={this.props.unitsList.units} fields={fields} />
        )
    }
}

export default UnitsList

