import { observable, action } from "mobx"

class SessionFlashBagStore {
    @observable item = null

    @action setMessage(message, type) {
        this.item = {
            "message": message,
            "type": type
        }
    }

    @action getMessage() {
        const message = this.item
        return message
    }

    @action clear() {
        this.item = null
    }
}

export default SessionFlashBagStore