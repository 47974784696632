import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import CategoryHeader from './CategoryHeader'
import { Grid } from 'react-bootstrap'
import ProductLine from './ProductLine'
import './style.css'

@inject('products')
@observer
class ProductList extends Component {

    render() {
        let category = null
        const sorted = this.props.products.productsGroupedByCategory
        const productList = sorted.map(function(product) {
            const previousCategory = category
            category = product.category
          return (
            <div key={product.id} className="product-line">
               { previousCategory !== category && <CategoryHeader name={category} />}
               <Grid>
                   <ProductLine product={product} />
               </Grid>
            </div>  
          )
        })
        return (<div id="product-list">{productList}</div>)
    }
}

export default ProductList