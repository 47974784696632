import React, {Component} from 'react'
import List from './List'
import { inject, observer } from 'mobx-react';

@inject('userList')
@observer
class UsersList extends Component {

    componentDidMount() {
        this.props.userList.getUsers()
    }

    render() {
        const fields = {
            'id': 'ID',
            'name': 'Naam',
            'email': 'E-mail',
            'created_at': 'Aangemaakt op',
            'last_login': 'Laatste login',
            'credits': 'Tegoed'
        }
        
        return (
            <List title="Gebruikers" plural_entity="users" fields={fields} entries={this.props.userList.getUsersWithNormalizedCredits} />
        )
    }
}

export default UsersList

