import React from 'react'
import {Panel} from 'react-bootstrap'
import ShopBasketList from './ShopCartList';

const ShopBasketContent = () => (
    <Panel bsStyle="success">
        <Panel.Heading>
            <h2>Uw bestelling</h2>
        </Panel.Heading>
        <Panel.Body>
        <p>Onderstaand vindt u de artikelen uit uw winkelwagen. U kunt deze nog aanpassen naar uw wensen door op een van de plus- en minknoppen te klikken achter een product.</p>
        <ShopBasketList />        
        </Panel.Body>
    </Panel>
)

export default ShopBasketContent
