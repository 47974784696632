import React, {Component} from 'react'
import Order from '../Order/Order'
import { observer, inject } from 'mobx-react';
import {Breadcrumb, Panel, Row, Col} from 'react-bootstrap'

@inject('orderList', 'userList', 'products')
@observer
class OrdersList extends Component {

    constructor(props) {
        super(props)
        this.props.userList.getUsers()
        this.props.orderList.getOrders()
        this.props.products.getProducts()
    }

    render() {
        const orders = this.props.orderList.orders.map(order => {
            return (<Order key={order.id} order={order} />)
        })
        return (
            <div>
            <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">Beheer</Breadcrumb.Item>
        <Breadcrumb.Item active>Bestellingen</Breadcrumb.Item>
        </Breadcrumb>

            <Panel bsStyle="success">
                <Panel.Heading>
                    <h2>Bestellingen</h2>
                </Panel.Heading>
                <Row className={"orderHeader"}>
                    <Col md={2}>Order nr</Col>
                    <Col md={2}>Afnemer</Col>
                    <Col md={1}>Totaalbedrag</Col>
                    <Col md={2} mdOffset={5} style={{textAlign: "right"}}>Besteld op</Col>
                </Row>
                { orders }
            </Panel>
            </div>
        )
    }
}

export default OrdersList

