import React, {Component} from 'react'
import ProductHighlights from './ProductHighlights'
import ProductList from './ProductList'
import { inject } from 'mobx-react';

@inject('products')
class Home extends Component {

    constructor(props){
        super(props)
        this.props.products.getProducts()
    }
    
    render = () => {
        return(
            <div>
                <ProductHighlights />
                <ProductList />
            </div>
        )
    }   
}

export default Home