import {observable, computed, action } from 'mobx'

class CartStore {
    @observable items = {}
    @observable sum = 0

    @computed
    get total() {
        let sum = 0
        for (let id in this.items) {
            sum += this.items[id]
        }
        return sum
    }

    count = (id) => {
        return this.items[id] ? this.items[id] : 0
    }

    @action
    clearCart() {
        this.items = {}
        this.sum = 0
    }

    @action
    addItem = (item) => {
        const id = item.id
        if (!this.items[id]) {
            this.items[id] = 0
        }
        this.sum += parseInt(item.price)
        this.items[id] += 1
    }

    @action
    removeItem = (item) => {
        const id = item.id
        this.sum -= item.price
        if (!this.items[id]) {
            return
        }
        if (this.items[id] === 1) {
            delete this.items[id]
            return
        }
        this.items[id] -= 1
    }
} 

export default CartStore