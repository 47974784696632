import React, {Component} from 'react'
import { Alert, Glyphicon, Grid, Button, Col, FormGroup, FormControl,ControlLabel } from 'react-bootstrap'
import Axios from 'axios';
import {Link, withRouter} from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import './login.css'

@inject('user')
@observer
class Login extends Component {
    state = {
        email: null,
        password: null,
        loginError: false
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.setState({loginError: false})
        Axios.post('https://api.hoevehoogland.nl/auth/login', {
            'email': this.state.email,
            'password': this.state.password
        })
        .then(res => {
            document.body.classList.remove("login-background");
            this.props.user.setUser(res.data)
            this.props.history.push("/")
        })
        .catch(error => {
          this.setState({loginError: true})
        })
    }

    handleChange = (event) => {
        if (event.target.name === 'email') {
            this.setState({email: event.target.value})
        }
        if (event.target.name === 'password') { 
            this.setState({password: event.target.value})
        }
    }

    render() {

        document.body.classList.add("login-background");

        return(
          <Grid>
            { this.props.history.location.search === "?password-changed" && <Alert bsStyle="success" style={{textAlign: "center"}}><Glyphicon glyph="info-sign"></Glyphicon> Uw wachtwoord is succesvol gewijzigd!</Alert> }
            <Grid className="login">
            { this.state.loginError && <Alert bsStyle="danger" >Uw e-mailadres en/of wachtwoord zijn incorrect</Alert> }
              <h2>Login</h2>
                <form onSubmit={this.handleSubmit}>
                <Col>
                  <FormGroup>
                    <ControlLabel>E-mail</ControlLabel>
                    <FormControl
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="myemail@email.com"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <ControlLabel htmlFor="password">Wachtwoord</ControlLabel>
                    <FormControl
                      type="password"
                      name="password"
                      id="password"
                      placeholder="********"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Button type="submit" bsStyle="primary">Inloggen</Button>
                <Link to="/lost-password" className="lost-password">Wachtwoord vergeten</Link>
              </form>
            </Grid>
          </Grid>

        )
    }   
}

export default withRouter(Login)