import React, { Component } from "react";
import accounting from 'accounting' 
import {
  Row,
  Panel,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  HelpBlock
} from "react-bootstrap";
import Axios from "axios";
import { inject, observer } from "mobx-react";
import UnitsSelect from "../Input/UnitsSelect";
import CategorySelect from "../Input/CategorySelect";
import { withRouter } from 'react-router'

@inject('categories', 'unitsList', 'products')
@observer
class ProductForm extends Component {

  state = {
    id: null,
    name: null,
    price: null,
    formattedPrice: null,
    category_id: null,
    unit_id: null,
    image: null,
    file: null,
  }

  constructor(props){
      super(props)
      this.props.categories.getCategories()
      this.props.unitsList.getUnits()
  }

  componentDidMount() {
    if (!this.props.match.params.id || isNaN(this.props.match.params.id)) {
      return
    }
    const id = this.props.match.params.id
    Axios.get('https://api.hoevehoogland.nl/products/' + id)
    .then(res => {
      this.setState(res.data)
      this.setState({formattedPrice: accounting.formatNumber(res.data.price/100, 2, '.', ',')})
    })
  }

  handleUnitChange = (value) => {
    this.setState({unit_id: value})
  }

  handleCategoryChange = (value) => {
    this.setState({category_id: value})
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === "file") {
      value = target.files[0]
    }
    const name = target.name;
    this.setState({[name]: value});
  }

  fileUpload(id) {
    const formData = new FormData();
    formData.append('file', this.state.file)
    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
    }
    return Axios.post('https://api.hoevehoogland.nl/products/' + id + '/image',formData,config)
  }

  submitForm = (event) => {
    event.preventDefault()

    if (this.state.id) {
      Axios.put('https://api.hoevehoogland.nl/products/' + this.state.id, this.state)
      .then(res => {
        this.props.products.reset()
        const id = res.data.id
        if (this.state.file) {
          this.fileUpload(id).then(res => {
            console.log(res)
          })
        }
        this.props.history.push("/admin/products")
      })
    } else {
      Axios.post('https://api.hoevehoogland.nl/products', this.state)
      .then(res => {
        this.props.products.reset()
        const id = res.data.id
        if (this.state.file) {
          this.fileUpload(id).then(res => {
            console.log(res)
          })
        }
        this.props.history.push("/admin/products")
      })
    }
  }

  render() {
    return (
      <Panel bsStyle="success">
        <Panel.Heading>
          <h2>Product</h2>
        </Panel.Heading>
        <Panel.Body>
          <form onSubmit={this.submitForm}>
            <Row>
              <Col md={6}>
                <FormGroup controlId="ProductFormName">
                  <ControlLabel>Naam</ControlLabel>
                  <FormControl
                    required  
                    name="name"
                    type="text"
                    defaultValue={this.state.name}
                    placeholder="Productnaam"
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup controlId="ProductFormPrice">
                  <ControlLabel>Prijs</ControlLabel>
                  <FormControl required name="price" type="text" defaultValue={this.state.formattedPrice} onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup controlId="ProductFormUnit">
                  <ControlLabel>Hoeveelheid</ControlLabel>
                  <FormControl
                    required  
                    name="unit_value"
                    type="text"
                    defaultValue={this.state.unit_value}
                    placeholder="Hoeveelheid"
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup controlId="ProductFormPrice">
                  <ControlLabel>Eenheid</ControlLabel>
                  <UnitsSelect value={this.state.unit_id} onChangedUnit={this.handleUnitChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup controlId="ProductFormCategory">
                  <ControlLabel>Categorie</ControlLabel>
                  <CategorySelect value={this.state.category_id} onSelectedCategory={this.handleCategoryChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                {this.state.image && <img alt="Product afbeelding" src={"https://api.hoevehoogland.nl/" + this.state.image} width="200"  />}
                <FormGroup controlId="ProductFormFile">
                  <ControlLabel>Afbeelding</ControlLabel>
                  <FormControl
                    name="file"
                    type="file"
                    onChange={this.handleInputChange}
                  />
                  <HelpBlock>Door een andere afbeelding te selecteren wordt de huidige afbeelding vervangen</HelpBlock>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup controlId="ProductFormSubmit">
              <Button bsStyle="primary" type="submit">
                Opslaan
              </Button>
            </FormGroup>
          </form>
        </Panel.Body>
      </Panel>
    );
  }
}
export default withRouter(ProductForm)