import React, {Component} from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import Axios from 'axios';

class HighlightButton extends Component {

    state = {
        highlight: true
    }

    componentDidMount() {
        this.setState({highlight: this.props.highlight})
    }

    handleClick = () => {
        Axios.put("https://api.hoevehoogland.nl/products/" + this.props.id, {highlight: !this.state.highlight})
        .then(res => {
            this.setState({highlight: !this.state.highlight})
        })
    }

    render() {
        const icon = this.state.highlight ? "exclamation-sign" : "remove-circle"
        const style = this.state.highlight ? "success" : "default"
        return(
            <Button onClick={this.handleClick} bsStyle={style} bsSize="xsmall"><Glyphicon glyph={icon}></Glyphicon></Button>
        )
    }
}

export default HighlightButton