import React, {Component} from 'react'
import {Switch, Route} from 'react-router'
import ProductCategory from './ProductCategory'
import Home from './Home'
import ShopBasketContent from './ShopBasketContent'
import CategoryForm from './admin/Form/CategoryForm';
import UnitForm from './admin/Form/UnitForm';
import AdminHome from './admin/Home'
import UsersList from './admin/List/UsersList'
import ProductsList from './admin/List/ProductsList'
import UnitsList from './admin/List/UnitsList'
import UserForm from './admin/Form/UserForm'
import OrdersList from './admin/List/OrdersList'
import ProductForm from './admin/Form/ProductForm'
import CategoriesList from './admin/List/CategoriesList'
import CheckoutComplete from './CheckoutComplete'
import Login from './Login'
import { inject } from 'mobx-react';
import { withRouter } from 'react-router'
import LostPassword from './LostPassword';
import AppContainer from './AppContainer';
import PasswordSent from './PasswordSent';
import DismissableAlert from './DismissableAlert';
import ChangePassword from './ChangePassword';

@inject('user', 'session')
class Main extends Component {

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
          this.props.session.item = null
        }
      }

    render() {
        return(
            <main>
                <DismissableAlert />
                <Switch>
                    <Route path='/login' component={Login}></Route>
                    <Route exact path='/lost-password' component={LostPassword}></Route>
                    <Route exact path='/password-sent' component={PasswordSent}></Route>
                    <Route exact path='/recover-password/:token' component={ChangePassword}></Route>
                    <AppContainer>
                        <Route exact path='/' component={Home}></Route>
                        <Route path='/categories/:id' component={ProductCategory}></Route>
                        <Route path='/admin/categories' exact component={CategoriesList}></Route>
                        <Route path='/admin/categories/create' exact component={CategoryForm}></Route>
                        <Route path='/admin/categories/edit/:id' exact component={CategoryForm}></Route>
                        <Route path='/cart' exact component={ShopBasketContent}></Route>
                        <Route path='/cart/checkout-complete' exact component={CheckoutComplete}></Route>
                        <Route path='/admin' exact component={AdminHome}></Route>
                        <Route path='/admin/users' exact component={UsersList}></Route>
                        <Route path='/admin/users/create' exact component={UserForm}></Route>
                        <Route path='/admin/users/edit/:id' exact component={UserForm}></Route>
                        <Route path='/admin/products' exact component={ProductsList}></Route>
                        <Route path='/admin/products/create' exact component={ProductForm}></Route>
                        <Route path='/admin/products/edit/:id' exact component={ProductForm}></Route>
                        <Route path='/admin/units' exact component={UnitsList}></Route>
                        <Route path='/admin/units/create' exact component={UnitForm}></Route>
                        <Route path='/admin/units/edit/:id' exact component={UnitForm}></Route>
                        <Route path='/admin/orders' exact component={OrdersList}></Route>
                    </AppContainer>
                </Switch>
            </main>
        )
    }

}

export default withRouter(Main)