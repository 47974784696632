import React, {Component} from 'react'
import { FormControl } from 'react-bootstrap'
import { observer, inject } from 'mobx-react';

@inject('categories')
@observer
class CategorySelect extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.onSelectedCategory(e.target.value)
    }

    render() {
        const value = this.props.value ? this.props.value :""
        const list = this.props.categories.categories.map(val => {
            return(<option key={val.id} value={val.id}>{val.name}</option>)
        })
        list.unshift(<option key="-1">- selecteer categorie -</option>)
        return(<FormControl value={value} onChange={this.handleChange} componentClass="select" name="category_id">{list}</FormControl>)
    }
}

export default CategorySelect