import React, { Component } from "react";
import {
  Row,
  Panel,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  InputGroup,
  HelpBlock
} from "react-bootstrap";
import Axios from "axios";
import { withRouter } from 'react-router'
import accounting from 'accounting'

class UserForm extends Component {

  state = {
    id: null,
    name: null,
    email: null,
    password: null,
    password2: null,
    equalPasswords: true,
    credits: null
  }

  componentDidMount() {
    console.log(this.props.match.params.id)
    if (!this.props.match.params.id || isNaN(this.props.match.params.id)) {
      return
    }
    const id = this.props.match.params.id
    Axios.get('https://api.hoevehoogland.nl/users/' + id)
    .then(res => {
      const credits = accounting.formatNumber(res.data.credits / 100, 2, ".", ",")
      this.setState(res.data)
      this.setState({credits: credits})
    })
  }

  comparePasswords() {
    if (!this.state.password) {
      return null
    }
    const compare = this.state.password === this.state.password2
    return (compare) ? "success" : "error" 
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  submitForm = (event) => {
    event.preventDefault()
  
    const state = this.state
    if (state.credits.indexOf(",") !== -1) {
      state.credits = state.credits.replace(/,/, "")
    } else {
      state.credits *= 100
    }

    if (this.state.id) {
      if (null === state.password) {
        delete state.password
      }
      Axios.put('https://api.hoevehoogland.nl/users/' + this.state.id, state)
      .then(res => {
        this.props.history.push("/admin/users")
      })
    } else {
      this.setState({password: this.getRandomInt(999999999999) })
      Axios.post('https://api.hoevehoogland.nl/users', state)
      .then(res => {
        this.props.history.push("/admin/users")
      })
    }
  }
  
  render() {

    return (
      <Panel bsStyle="success">
        <Panel.Heading>
          <h2>Gebruiker</h2>
        </Panel.Heading>
        <Panel.Body>
          <form onSubmit={this.submitForm}>
            <Row>
              <Col md={6}>
                <FormGroup controlId="UserFormUsername">
                  <ControlLabel>Naam</ControlLabel>
                  <FormControl
                    required  
                    name="name"
                    type="text"
                    defaultValue={this.state.name}
                    placeholder="Naam van de gebruiker"
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup controlId="UserFormEmail">
                  <ControlLabel>E-mail</ControlLabel>
                  <FormControl required name="email" type="text" defaultValue={this.state.email} placeholder="me@myemail.com" onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup controlId="UserFormCredit">
                  <ControlLabel>Tegoed (in euro's)</ControlLabel>
                  <InputGroup >
                    <InputGroup.Addon>&euro;</InputGroup.Addon>
                    <FormControl required defaultValue={this.state.credits} name="credits" onChange={this.handleInputChange} />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            { this.state.id !== null && 
            <Row>
              <Col md={6}>
                <FormGroup controlId="UserFormPassword">
                  <ControlLabel>Wachtwoord</ControlLabel>
                  <FormControl type="password" name="password" placeholder="Wachtwoord" onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup controlId="UserFormPassword2" validationState={this.comparePasswords()} >
                  <ControlLabel>Wachtwoord (nogmaals)</ControlLabel>
                  <FormControl 
                  type="password" name="password2" placeholder="Wachtwoord" onChange={this.handleInputChange} />
                  { this.state.password !== this.state.password2 && <HelpBlock>De wachtwoorden zijn niet gelijk</HelpBlock> }
                </FormGroup>
              </Col>
            </Row>
            }
            <FormGroup controlId="UserFormPassword2">
              <Button bsStyle="primary" type="submit">
                Opslaan
              </Button>
            </FormGroup>
          </form>
        </Panel.Body>
      </Panel>
    );
  }
}
export default withRouter(UserForm)
