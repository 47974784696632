import React, {Component} from 'react'
import {Button,Navbar, Nav, NavItem} from 'react-bootstrap'
import {Link, withRouter} from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import ShopBasket from './ShopBasket';
import { inject, observer } from 'mobx-react';
import accounting from 'accounting'

@inject('user', 'cart')
@observer
class Header extends Component {

    handleLogout = () => {
        this.props.user.logout()
        this.props.history.push("/login")
    }
    
    render() {
        if (this.props.user.token === null) {
            return null
        }

        return (
            <Navbar inverse staticTop>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to={"/"}>Hoeve Hoogland</Link>
                    </Navbar.Brand>
                </Navbar.Header>
                <Nav>
                    <LinkContainer to="/" exact>
                        <NavItem>Home</NavItem>
                    </LinkContainer>
                    { this.props.user.isAdmin ?
                    <LinkContainer to="/admin">
                        <NavItem>Beheer</NavItem>
                    </LinkContainer>
                    :
                    null
                    }
                </Nav>
                <Nav pullRight>
                    <NavItem onClick={this.handleLogout}>Uitloggen</NavItem>
                </Nav>
                <Navbar.Text pullRight>Tegoed: &euro;{ accounting.formatNumber(this.props.user.credits/100,2,".",",")}</Navbar.Text>
                <Navbar.Text pullRight>{this.props.user.username}</Navbar.Text>
                <Nav pullRight>
                    <LinkContainer to="/cart" className="cart-container">
                        <NavItem>
                            <ShopBasket />
                        </NavItem>
                    </LinkContainer>
                </Nav>
                { this.props.cart.sum ?
                <Navbar.Form pullRight>
                    <LinkContainer to="/cart" className="cart-container">
                     <Button bsStyle="primary">Afrekenen</Button>
                    </LinkContainer>
                </Navbar.Form>
                 : null }
            </Navbar>
        )
    }
}

export default withRouter(Header)