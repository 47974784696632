import { action, observable, computed } from "mobx";
import accounting from 'accounting'
import Axios from 'axios'

class ProductStore {
    @observable products = []

    @action
    getProducts(reset) {
        Axios.get('https://api.hoevehoogland.nl/products')
        .then(res => {
          this.products = res.data
        })
    }

    @computed
    get productsGroupedByCategory() {
        let sorted = this.products.slice()
        const filtered = sorted.filter(product => product.enabled === true)
        filtered.sort(function(a,b) {
            if (a.category_weight > b.category_weight) {
                return 1
            }
            if (a.category_weight < b.category_weight) {
                return -1
            }
            return 0
        })
        return filtered
    }

    @computed
    get highlights() {
        let items = this.products.filter((val) => {
            return (val.highlight === true && val.enabled === true)
        })
        return items.slice(0,4)
    }

    @action
    reset() {
        this.products = []
    }

    @computed
    get normalized() {
        let items = this.products
        for (let i in items) {
            items[i].price = accounting.formatMoney(items[i].price / 100, "€", 2, ".", ",")
        }
        return items
    }

    getList(categoryId) {
        return this.products.filter((val) => {
            return (val.category_id === parseInt(categoryId))
        })
    }

    @action
    getProduct(id) {
        return this.products.find((product) => parseInt(product.id) === parseInt(id));
    }
}

export default ProductStore