import { observable, action } from 'mobx'
import Axios from 'axios'

class AuthUserStore {

    @observable username = null
    @observable token = null
    @observable credits = 0
    @observable isAdmin = true

    @action
    setUser(user) {
        this.username = user.username
        this.token = user.token
        this.credits = user.credits
        this.isAdmin = user.isAdmin
        Axios.defaults.headers.common = {
            "X-Token": user.token
        };
        sessionStorage.setItem('user', JSON.stringify(user))
    }

    @action
    logout() {
        sessionStorage.removeItem('user')
        this.username = null
        this.token = null
        this.credits = 0
        this.isAdmin = false
    }
}

export default AuthUserStore