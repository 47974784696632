import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Alert } from 'react-bootstrap'

@inject('session')
@observer
class DismissableAlert extends Component {

    componentDidUpdate() {
        setTimeout(() => {
            this.props.session.clear()
        }, 10000)
    }

    render() {
        return this.props.session.item && <Alert bsStyle={this.props.session.item.type}>{this.props.session.item.message}</Alert>
    }
}

export default DismissableAlert