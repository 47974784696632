import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Row,Col,Button,ButtonToolbar } from 'react-bootstrap'
import { withRouter, Redirect } from 'react-router-dom'
import Axios from 'axios'
import accounting from 'accounting'
import ProductLine from './ProductLine';

@inject('cart', 'products', 'user', 'session')
@observer
class ShopBasketList extends Component {

    getCartContents() {
        let contents = []
        for (let i in this.props.cart.items) {
            let item = {
                product_id: i,
                quantity: this.props.cart.items[i]
            }
            contents.push(item)
        }
        return contents
    }

    submitCart = () => {
        Axios.post('https://api.hoevehoogland.nl/cart/checkout', {
            order: this.getCartContents()
        })
        .then(res => {
            if (res.status === 201) {
                this.props.user.credits = res.data.credits
                this.props.cart.clearCart();
                this.props.history.push('/cart/checkout-complete')
            }
        })
        .catch(error => {
            this.props.session.setMessage(error.response.data.message, "danger")
            console.log(error.response)
        })
    }

    handleCancel = () => {
        this.props.history.push('/')
    }

    render() {
        const list = Object.keys(this.props.cart.items).map((id) => {
            const product = this.props.products.getProduct(id)
            return (
                <ProductLine key={product.id} product={product} />
            )
        })

        if (!this.props.cart.sum) {
            return <Redirect to="/" /> 
        }

        console.log(this.props.cart.sum)
        return(

            <form>
            {list}
            { this.props.cart.sum ? 
                <Row className="cart-total">
                    <Col md={9} style={{textAlign: 'right'}}><b>Totaal</b></Col>
                    <Col md={3}>&euro; { accounting.formatNumber(this.props.cart.sum/100, 2, ".", ",") }</Col>
                </Row> : null 
            }
            <ButtonToolbar>
                <Button className="pull-left" onClick={this.handleCancel}>Annuleren</Button>
                <Button className="pull-right" bsStyle="primary" onClick={this.submitCart}>Verstuur bestelling</Button>
            </ButtonToolbar>
            </form>
        )
    }
}

export default withRouter(ShopBasketList)