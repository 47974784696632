import React, {Component} from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import Axios from 'axios';

class ToggleButton extends Component {

    state = {
        enabled: true
    }

    componentDidMount() {
        this.setState({enabled: this.props.enabled})
    }

    handleClick = () => {
        Axios.put("https://api.hoevehoogland.nl/products/" + this.props.id, {enabled: !this.state.enabled})
        .then(res => {
            this.setState({enabled: !this.state.enabled})
        })
    }

    render() {
        const icon = this.state.enabled ? "eye-open" : "eye-close"
        const style = this.state.enabled ? "default" : "danger"
        return(
            <Button onClick={this.handleClick} bsStyle={style} bsSize="xsmall"><Glyphicon glyph={icon}></Glyphicon></Button>
        )
    }
}

export default ToggleButton