import React,{Component} from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class EditButton extends Component {

    handle = () => {
        this.props.history.push("/admin/" + this.props.entity + "/edit/" + this.props.id)
    }

    render() {
        return(
            <Button bsSize="xsmall" title="Bewerk" onClick={this.handle}>
                <Glyphicon glyph="pencil"></Glyphicon>
            </Button>
        )
    }
}

export default withRouter(EditButton)