import React from 'react'
import { Grid } from 'react-bootstrap'
import {Link } from 'react-router-dom'

const PasswordSent = (props) => (
    <Grid className="login">
    <h2>E-mail verstuurd</h2>
    <p>Er is een e-mail verstuurd naar uw opgegeven e-mailadres.</p>
    <p>Volg de instructies in de e-mail om een nieuw wachtwoord in te stellen.</p>
    <Link to="/login">Terug naar inlogscherm</Link>
    </Grid>
)

export default PasswordSent