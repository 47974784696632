import React, {Component} from 'react'
import List from './List'
import { inject, observer } from 'mobx-react';

@inject('categories')
@observer
class CategoriesList extends Component {

    componentDidMount() {
        this.props.categories.getCategories()
    }

    render() {
        const fields = {
            'id': 'ID',
            'name': 'Naam',
        }
        
        return (
            <List title="Categorieën" plural_entity={"categories"} entries={this.props.categories.categories} fields={fields} />
        )
    }
}

export default CategoriesList

