import React, {Component} from 'react'
import {Row, Col } from 'react-bootstrap'
import '../style.css'
import AdminTile from './AdminTile';
import { observer,inject } from 'mobx-react';

@inject('userList', 'categories', 'products', 'orderList', 'unitsList')
@observer
class AdminHome extends Component {

    constructor(props) {
        super(props)
        this.props.userList.getUsers()
        this.props.categories.getCategories()
        this.props.products.getProducts()
        this.props.orderList.getOrders()
        this.props.unitsList.getUnits()
    }

    render() {
        return(
            <Row>
                <Col md={2}>
                    <AdminTile to="/admin/orders" icon="stats" label="Bestellingen" />
                </Col>
                <Col md={2}>
                    <AdminTile to="/admin/users" icon="user" label="Gebruikers" />
                </Col>
                <Col md={2}>
                    <AdminTile to="/admin/products" icon="apple" label="Producten" />
                </Col>
                <Col md={2}>
                    <AdminTile to="/admin/categories" icon="list" label="Categorieën" />
                </Col>
                <Col md={2}>
                    <AdminTile to="/admin/units" icon="th-large" label="Eenheden" />
                </Col>
            </Row>
        )
    }
    

}

export default AdminHome