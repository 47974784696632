import React, {Component} from 'react'
import { inject, observer } from 'mobx-react';
import { Grid, Col, Row } from 'react-bootstrap'
import ProductTile from './ProductTile'

@inject('products')
@observer
class ProductCategory extends Component {
    
    render() {
        const id = this.props.match.params.id
        const productList = this.props.products.getList(id).map(function(product) {
            return (
                <Col md={3} key={product.id}>
                    <ProductTile product={product} showImage={false} />
                </Col>
            )
        })
        return(
            <Grid>
                <Row>{productList}</Row>           
            </Grid>
        )
    }
}

export default ProductCategory